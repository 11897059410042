// let appID = "local.665d6cf23c1982.22651418"; // Dev
let appID = "local.66a8911c471391.99624248"; // Prod
let domainMain = '3plportal-test.unilever-rus.ru';
let domain = '3pl-bitrix-test.unilever-rus.ru';

if (window.location.hostname === "3plportal.unilever-rus.ru") {
  appID = "local.66a79f7eed3dd9.87453794"; // Prod
  domainMain = "3plportal.unilever-rus.ru"; // Prod
  domain = '3pl-bitrix-prod.unilever-rus.ru';
}

let poList = 41;
let requestsList = 45;
let messagesList = 35;

  let dockBookingListId = 47; // Prod
  let shipmentList = 67; // Prod
  let logList = 80; // Prod
  let fieldsLogs = {
    PROPERTY_721: { name: "Номер рейса", id: 721, code: "PROPERTY_721" },
    PROPERTY_742: { name: "Создал (Email)", id: 742, code: "PROPERTY_742" },
    PROPERTY_722: { name: "Изменил (Email)", id: 722, code: "PROPERTY_722" },
    PROPERTY_723: { name: "Дата последнего изменения", id: 723, code: "PROPERTY_723" },
    PROPERTY_724: { name: "Время последнего изменения", id: 724, code: "PROPERTY_724" },
  }
  let fieldsShipment = {
    PROPERTY_449: { name: "Обьем накладной", id: 449, code: "PROPERTY_449" },
    PROPERTY_451: { name: "Вес накладной", id: 451, code: "PROPERTY_451" },
    PROPERTY_453: {
      name: "Количество паллет по накладной",
      id: 453,
      code: "PROPERTY_453",
    },
    PROPERTY_455: {
      name: "Количество коробов по накладной",
      id: 455,
      code: "PROPERTY_455",
    },
    PROPERTY_461: { name: "Номер накладной", id: 461, code: "PROPERTY_461" },
    PROPERTY_617: { name: "Warehouse ID", id: 617, code: "PROPERTY_617" },
    PROPERTY_725: { name: "Фактическая дата прибытия (COD)", id: 725, code: "PROPERTY_725" },
    PROPERTY_726: { name: "Фактическое время прибытия (COD)", id: 726, code: "PROPERTY_726" },
    PROPERTY_727: { name: "Фактическая дата убытия (Actual Departure)", id: 727, code: "PROPERTY_727" },
    PROPERTY_728: { name: "Фактическое время убытия (Actual Departure)", id: 728, code: "PROPERTY_728" },
    PROPERTY_729: { name: "Комментарии (Remark COD)", id: 729, code: "PROPERTY_729" },
    PROPERTY_730: { name: "Стоимость рейса", id: 730, code: "PROPERTY_730" },
    PROPERTY_627: { name: "Точка доставки", id: 627, code: "PROPERTY_627" },
  };
  let fields = {
    PROPERTY_341: { name: "Номер рейса", id: 341, code: "PROPERTY_341" }, // Done
    PROPERTY_589: { name: "Номер накладной", id: 589, code: "PROPERTY_589" }, // Done
    PROPERTY_291: { name: "Склад активности", id: 291, code: "PROPERTY_291" }, // Done
    PROPERTY_353: { name: "Точка доставки", id: 353, code: "PROPERTY_353" }, // Done
    PROPERTY_369: { name: "Общий объем", id: 369, code: "PROPERTY_369" }, // Done
    PROPERTY_367: { name: "Общий вес", id: 367, code: "PROPERTY_367" }, // Done
    PROPERTY_365: {
      name: "Общее количество паллет",
      id: 365,
      code: "PROPERTY_365",
    }, // Done
    PROPERTY_363: {
      name: "Общее количество коробов",
      id: 363,
      code: "PROPERTY_363",
    }, // Done
    PROPERTY_281: { name: "Appointment Date", id: 281, code: "PROPERTY_281" }, // Done
    PROPERTY_439: {
      name: "Shipment Start Date",
      id: 439,
      code: "PROPERTY_439",
    }, // Done
    PROPERTY_323: { name: "Appointment Time", id: 323, code: "PROPERTY_323" }, // Done
    PROPERTY_355: {
      name: "Наименование точки доставки",
      id: 355,
      code: "PROPERTY_355",
    }, // Done
    PROPERTY_357: {
      name: "Область точки доставки",
      id: 357,
      code: "PROPERTY_357",
    }, // Done
    PROPERTY_359: {
      name: "Город точки доставки",
      id: 359,
      code: "PROPERTY_359",
    }, // Done
    PROPERTY_361: {
      name: "Адрес точки доставки",
      id: 361,
      code: "PROPERTY_361",
    }, // Done
    PROPERTY_373: { name: "Точка отгрузки", id: 373, code: "PROPERTY_373" }, // Done
    PROPERTY_375: { name: "Точка доставки", id: 375, code: "PROPERTY_375" }, // Done
    PROPERTY_339: { name: "Тип трака", id: 339, code: "PROPERTY_339" }, // Done
    PROPERTY_377: { name: "Код перевозчика", id: 377, code: "PROPERTY_377" }, // Done
    PROPERTY_379: {
      name: "Наименование перевозчика",
      id: 379,
      code: "PROPERTY_379",
    }, // Done
    PROPERTY_317: { name: "Количество слотов", id: 317, code: "PROPERTY_317" }, // Done
    PROPERTY_419: {
      name: "Количество слотов (ручной)",
      id: 419,
      code: "PROPERTY_419",
    }, // Done
    PROPERTY_381: { name: "Тип процесса", id: 381, code: "PROPERTY_381" }, // Done
    PROPERTY_285: { name: "Док", id: 285, code: "PROPERTY_285" }, // Done
    PROPERTY_289: { name: "Группа доступа", id: 289, code: "PROPERTY_289" }, // Done
    PROPERTY_311: {
      name: "Статус",
      id: 311,
      code: "PROPERTY_311",
      options: {
        367: { id: 367, code: "367", title: "Pending" },
        369: { id: 369, code: "369", title: "Infeasible" },
        371: { id: 371, code: "371", title: "Confirmed" },
      },
    }, // Done
    PROPERTY_325: {
      name: "Appointment End Time",
      id: 325,
      code: "PROPERTY_325",
    }, // Done
    PROPERTY_385: {
      name: "Загрузка/выгрузка",
      id: 385,
      code: "PROPERTY_385",
      options: {
        399: { id: 399, code: "399", title: "Drop" },
        401: { id: 401, code: "401", title: "Pick" },
      },
    }, // Done
    PROPERTY_387: {
      name: "В работе",
      id: 387,
      code: "PROPERTY_387",
      options: {
        403: { id: 403, code: "403", title: "Y" },
        405: { id: 405, code: "405", title: "N" },
      },
    }, // Done
    PROPERTY_391: { name: "ФИО водителя", id: 391, code: "PROPERTY_391" }, // Done
    PROPERTY_393: { name: "Номер паспорта", id: 393, code: "PROPERTY_393" }, // Done
    PROPERTY_395: {
      name: "Номер телефона водителя",
      id: 395,
      code: "PROPERTY_395",
    }, // Done
    PROPERTY_397: { name: "Driver Tax Number", id: 397, code: "PROPERTY_397" }, // Done
    PROPERTY_399: {
      name: "У трака есть прицеп?",
      id: 399,
      code: "PROPERTY_399",
      options: {
        407: { id: 407, code: "407", title: "Y" },
        409: { id: 409, code: "409", title: "N" },
      },
    }, // Done
    PROPERTY_425: { name: "Номер прицепа", id: 425, code: "PROPERTY_425" }, // Done
    PROPERTY_781: { name: "Марка прицепа/полуприцепа", id: 781, code: "PROPERTY_781" }, // Done
    PROPERTY_782: { name: "Тип владения прицепом/полуприцепом", id: 782, code: "PROPERTY_782" }, // Done
    PROPERTY_783: { name: "Название документа владения прицепом/полуприцепом", id: 783, code: "PROPERTY_783" }, // Done
    PROPERTY_784: { name: "Номер документа владения прицепом/полуприцепом", id: 784, code: "PROPERTY_784" }, // Done
    PROPERTY_785: { name: "Дата документа владения прицепом/полуприцепом", id: 785, code: "PROPERTY_785" }, // Done
    PROPERTY_401: { name: "Марка машины", id: 401, code: "PROPERTY_401" }, // Done
    PROPERTY_403: { name: "Номер машины", id: 403, code: "PROPERTY_403" }, // Done
    PROPERTY_405: {
      name: "Truck Ownership Type",
      id: 405,
      code: "PROPERTY_405",
    }, // Done
    PROPERTY_778: { name: "Название документа владения автомобилем/тягачом", id: 778, code: "PROPERTY_778" }, // Done
    PROPERTY_779: { name: "Номер документа владения автомобилем/тягачом", id: 779, code: "PROPERTY_779" }, // Done
    PROPERTY_780: { name: "Дата документа владения автомобилем/тягачом", id: 780, code: "PROPERTY_780" }, // Done
    PROPERTY_407: { name: "Remark", id: 407, code: "PROPERTY_407" }, // Done
    PROPERTY_409: {
      name: "Является экспедитором",
      id: 409,
      code: "PROPERTY_409",
      options: {
        411: { id: 411, code: "411", title: "Y" },
        413: { id: 413, code: "413", title: "N" },
      },
    }, // Done
    PROPERTY_411: { name: "Перевозчик", id: 411, code: "PROPERTY_411" }, // Done
    PROPERTY_413: {
      name: "Адрес Перевозчика",
      id: 413,
      code: "PROPERTY_413",
    }, // Done
    PROPERTY_415: {
      name: "ИНН Перевозчика",
      id: 415,
      code: "PROPERTY_415",
    }, // Done
    PROPERTY_417: {
      name: "Телефон Перевозчика",
      id: 417,
      code: "PROPERTY_417",
    }, // Done
    PROPERTY_758: {
      name: "№ заявки от экспедитора в адрес перевозчика",
      id: 758,
      code: "PROPERTY_758",
    }, // Done
    PROPERTY_759: {
      name: "Дата заявки от экспедитора в адрес перевозчика",
      id: 759,
      code: "PROPERTY_759",
    }, // Done
    PROPERTY_787: {
      name: "Сведения о форме организации",
      id: 5553,
      code: "PROPERTY_787",
    }, // Done
    PROPERTY_760: {
      name: "ИНН",
      id: 760,
      code: "PROPERTY_760",
    }, // Done
    PROPERTY_761: {
      name: "Фамилия ИП",
      id: 761,
      code: "PROPERTY_761",
    }, // Done
    PROPERTY_762: {
      name: "Имя ИП",
      id: 762,
      code: "PROPERTY_762",
    }, // Done
    PROPERTY_763: {
      name: "Отчество ИП",
      id: 763,
      code: "PROPERTY_763",
    }, // Done
    PROPERTY_776: {
      name: "Название организации",
      id: 776,
      code: "PROPERTY_776",
    }, // Done
    PROPERTY_764: {
      name: "Индекс",
      id: 764,
      code: "PROPERTY_764",
    }, // Done
    PROPERTY_765: {
      name: "Регион",
      id: 765,
      code: "PROPERTY_765",
    }, // Done
    PROPERTY_766: {
      name: "Район",
      id: 766,
      code: "PROPERTY_766",
    }, // Done
    PROPERTY_767: {
      name: "Город",
      id: 767,
      code: "PROPERTY_767",
    }, // Done
    PROPERTY_768: {
      name: "Населенный пункт",
      id: 768,
      code: "PROPERTY_768",
    }, // Done
    PROPERTY_769: {
      name: "Улица",
      id: 769,
      code: "PROPERTY_769",
    }, // Done
    PROPERTY_770: {
      name: "Дом",
      id: 770,
      code: "PROPERTY_770",
    }, // Done
    PROPERTY_771: {
      name: "Корпус",
      id: 771,
      code: "PROPERTY_771",
    }, // Done
    PROPERTY_772: {
      name: "Квартира",
      id: 772,
      code: "PROPERTY_772",
    }, // Done
    PROPERTY_773: {
      name: "Контакты: Телефон",
      id: 773,
      code: "PROPERTY_773",
    }, // Done
    PROPERTY_421: {
      name: "Ручной appointment",
      id: 421,
      code: "PROPERTY_421",
      options: {
        415: { id: 415, code: "415", title: "N" },
        417: { id: 417, code: "417", title: "Y" },
      },
    }, // Done
    PROPERTY_477: { name: "LDD Date", id: 477, code: "PROPERTY_477" }, // Done
    PROPERTY_665: { name: "LDD Time", id: 665, code: "PROPERTY_665" }, // Done
    PROPERTY_609: {
      name: "Самая последняя точка доставки (Details)",
      id: 609,
      code: "PROPERTY_609",
    }, // Done
    PROPERTY_613: {
      name: "Номер накладной (Ручной Appointment)",
      id: 613,
      code: "PROPERTY_613",
    }, // Done
    PROPERTY_607: {
      name: "Самый первый склад отгрузки (Details)",
      id: 607,
      code: "PROPERTY_607",
    }, // Done
    PROPERTY_621: {
      name: "Дата для запросов букинга (техническая, НЕ ТРОГАТЬ)",
      id: 621,
      code: "PROPERTY_621",
    },
    // PROPERTY_473: { name: "Время доставки", id: 473, code: "PROPERTY_473" },
  };

export {
  appID,
  dockBookingListId,
  domainMain,
  fields,
  fieldsShipment,
  shipmentList,
  logList,
  fieldsLogs,
  poList,
  messagesList,
  requestsList
};

export const isLocalhost = window.location.host == ('localhost:3000' || 'localhost:3001') && true || false;

export const paramsLocalhostApi = 'ss=f786dfbr3k';

export const timeSessionApp = 1000 * 60 * 60 * 8;

export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const getValueProperty = (object, propertyID) => {
  if (!object["PROPERTY_" + propertyID]) {
    return "";
  }
  let result = Object.values(object["PROPERTY_" + propertyID]);
  if (result.length == 1) {
    result = result[0];
  }
  return result;
};

export const redirectAuth = () => {
  if (!getWithExpiry("authApp3pl")) {
    window.location.href = "https://"+ domain +"/oauth/authorize/?client_id=" + appID;
  }
};


export const f = x => ((x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0));



export const removeDuplicates = (str) => {
  // Разбиваем строку на массив символов
  var chars = str.split('; ');

  // Создаем объект для отслеживания уникальных символов
  var seen = {};

  // Фильтруем массив символов, оставляя только уникальные
  var uniqueChars = chars.filter(function(char) {
    if (seen[char]) {
      return false; // Если символ уже был встречен, пропускаем его
    } else {
      seen[char] = true; // Запоминаем встреченный символ
      return true; // Оставляем уникальный символ
    }
  });

  // Объединяем уникальные символы обратно в строку и возвращаем
  return uniqueChars.join('; ');
}
